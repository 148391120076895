a {
  -webkit-transition: color 500ms ease;
  -moz-transition: color 500ms ease;
  -ms-transition: color 500ms ease;
  -o-transition: color 500ms ease;
  transition: color 500ms ease;
}

.transition {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

html {
  background: #eee;
}

body {
  background: #fff;
}

.axis {
  margin: 0 auto;
  max-width: 100%;
  width: 960px;
}

#all {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.alert {
  margin: 10px 0 20px;
  color: #000;
}

.alert p {
  display: block;
  margin: 0 0 5px;
  padding: 8px 12px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
}

.alert.alert-success p {
  background: #91ba18;
}

.alert.alert-error p {
  background: red;
}

.simpleList {
  overflow: hidden;
}

.simpleList .item {
  display: inline-block;
  vertical-align: top;
}

table {
  max-width: 100%;
}

table img {
  max-width: 100%;
  height: auto;
}

#header {
  padding: 25px 0 20px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 500;
  background: #fff;
}

#header .logo {
  float: left;
  margin-right: 30px;
}

#header .search {
  float: right;
  margin-left: 20px;
}

#header .nav {
  margin-top: 8px;
}

#header .mainMenu > ul {
  cursor: default;
}

#header .mainMenu > ul > li {
  display: block;
  float: left;
  margin-left: 2px;
}

#header .mainMenu > ul > li:first-child {
  margin-left: 0;
}

#header .mainMenu > ul > li > a {
  display: block;
  font-size: 12px;
  font-size: 1.2rem;
  text-transform: uppercase;
  line-height: 1;
  padding: 13px 10px;
  position: relative;
}

#header .mainMenu > ul > li > a:after {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  background: transparent;
}

#header .mainMenu > ul > li > a:hover {
  color: #646464;
}

#header .mainMenu > ul > li.current > a:after,
#header .mainMenu > ul > li > a.current:after,
#header .mainMenu > ul > li > a:hover:after {
  background: #91ba18;
}

#header .menuChanger,
#header #menuChanger {
  display: none;
}

@media all and (min-width: 980px) {
  #header.overHeader {
    position: fixed;
    border-bottom: 2px solid #e1e1e1;
    padding: 5px 0;
  }
}

#main {
  margin-top: 97px;
}

.banners {
  background: url(../img/slider-bg.jpg) no-repeat center top;
  height: 492px;
  margin-bottom: 35px;
}

.banners .bannersList {
  height: 454px;
  position: relative;
  overflow: hidden;
}

.banners .banner {
  position: absolute;
  width: 100%;
  height: 100%;
}

.banners .banner > img {
  display: block;
  width: 100%;
}

.banners .banner .textContent {
  position: absolute;
  left: 30px;
  top: 294px;
  width: 375px;
  min-height: 140px;
  padding: 14px 15px 14px 10px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99FFFFFF, endColorstr=#99FFFFFF);
  *zoom: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.banners .banner .textContent .name {
  margin: 0 0 10px;
  font-size: 20px;
}

.banners .banner .textContent p {
  font-size: 11px;
  font-size: 1.1rem;
  line-height: 1.3;
}

.banners .pager {
  position: absolute;
  z-index: 300;
  cursor: default;
}

.banners .pager span {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  border-radius: 5px;
  overflow: hidden;
  text-indent: 10px;
  line-height: 10px;
  background: #fff;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  cursor: pointer;
}

.banners .pager span:hover {
  background: #bae539;
}

.banners .pager span.cycle-pager-active {
  background: #91ba18;
}

.banners .arrows {
  position: absolute;
  z-index: 400;
}

.layoutBanners .arrows {
  top: 294px;
  left: 406px;
  width: 20px;
}

.layoutBanners .arrows .prev {
  margin-bottom: 1px;
}

.layoutBanners .pager {
  right: 35px;
  bottom: 25px;
}

.arrows {
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.arrows > div {
  text-align: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-bottom: 4px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.arrows > div:before {
  font-family: FontAwesome;
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

.arrows > div:hover {
  color: #91ba18;
}

.arrows .prev {
  padding-right: 3px;
}

.arrows .prev:before {
  content: '\f053';
}

.arrows .next:before {
  content: '\f054';
}

.arrows.fullArrows > div {
  width: 20px;
  height: 20px;
  padding: 0;
  display: inline-block;
  line-height: 16px;
  color: #fff;
}

.arrows.fullArrows > div:before {
  font-size: 10px;
}

.arrows.fullArrows .prev {
  padding-right: 1px;
}

.arrows.fullArrows .next {
  padding-left: 1px;
}

.categoriesInLine {
  overflow: hidden;
}

.categoriesInLine a {
  display: block;
  float: left;
  height: 38px;
  line-height: 38px;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  background: #91ba18;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  border-left: 1px solid #7ea60a;
  border-right: 1px solid #bde644;
  padding: 0;
}

.categoriesInLine a:first-child {
  border-left: 0;
}

.categoriesInLine a:last-child {
  border-right: 0;
}

.categoriesInLine.count_1 a {
  width: 100%;
}

.categoriesInLine.count_2 a {
  width: 50%;
}

.categoriesInLine.count_3 a {
  width: 33.33333%;
}

.categoriesInLine.count_4 a {
  width: 25%;
}

.categoriesInLine.count_5 a {
  width: 20%;
}

.categoriesInLine.count_6 a {
  width: 16.66667%;
}

.categoriesInLine.count_7 a {
  width: 14.28571%;
}

.categoriesInLine.count_8 a {
  width: 12.5%;
}

.categoriesInLine.count_9 a {
  width: 11.11111%;
}

.categoriesInLine.count_10 a {
  width: 10%;
}

.categoriesInLine.count_11 a {
  width: 9.09091%;
}

.categoriesInLine.count_12 a {
  width: 8.33333%;
}

.mainBlocks .item {
  margin-bottom: 30px;
}

.mainBlocks .head {
  margin-bottom: 15px;
}

.mainBlocks .head .img {
  float: left;
  position: relative;
  height: 45px;
  margin-right: 5px;
}

.mainBlocks .head .img img {
  display: block;
  position: relative;
  left: -3px;
}

.mainBlocks .head .name {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
}

.mainBlocks .textContent {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.3;
  min-height: 135.2px;
  min-height: 13.52rem;
}

.readMore {
  text-align: right;
}

.readMore a {
  display: inline-block;
  position: relative;
  height: 18px;
  line-height: 15px;
  padding-left: 25px;
  -webkit-transition: color 500ms ease;
  -moz-transition: color 500ms ease;
  -ms-transition: color 500ms ease;
  -o-transition: color 500ms ease;
  transition: color 500ms ease;
}

.readMore a:before {
  content: '\f054';
  font-family: FontAwesome;
  font-size: 10px;
  width: 14px;
  padding-left: 2px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  border: 1px solid #646464;
  border-radius: 9px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(0);
  -webkit-transition: border-color 500ms ease, transform 500ms ease;
  -moz-transition: border-color 500ms ease, transform 500ms ease;
  -ms-transition: border-color 500ms ease, transform 500ms ease;
  -o-transition: border-color 500ms ease, transform 500ms ease;
  transition: border-color 500ms ease, transform 500ms ease;
}

.readMore a:hover:before {
  border-color: #91ba18;
  transform: translateX(5px);
}

.mainIndex > section {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mainIndex .mainBlock {
  margin-bottom: 0;
}

.mainIndex .newsSimpleItem {
  margin-top: 50px;
}

.actionList {
  position: relative;
}

.actionList .arrows {
  display: none;
  height: 0;
}

.actionList .arrows > div {
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -15px;
}

.actionList .arrows .prev {
  left: 0;
}

.actionList .arrows .next {
  right: 0;
}

.actionList .slide {
  text-align: justify;
  line-height: 1px;
  font-size: 1px;
}

.actionList .slide:after {
  content: ' ';
  display: inline-block;
  width: 95%;
  height: 1px;
  overflow: hidden;
  vertical-align: top;
}

.actionList .item {
  display: inline-block;
  vertical-align: middle;
}

.actionList.working {
  text-align: left;
  padding-left: 50px;
  padding-right: 50px;
}

.actionList.working .arrows {
  display: block;
}

.actionList.working .slide {
  text-align: left;
}

.actionList.working .slide:after {
  display: none;
}

.brandsList {
  margin: 30px 0;
}

.brandsList .item {
  display: inline-block;
  height: 45px;
  line-height: 45px;
  opacity: 0.4;
  filter: alpha(opacity=40);
  *zoom: 1;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.brandsList .item img {
  margin: 0 5px;
}

.brandsList .item:hover {
  opacity: 0.2;
  filter: alpha(opacity=20);
  *zoom: 1;
}

.brandsList.working .item img {
  margin: 0;
}

.newsSimpleItem .newsItem {
  overflow: hidden;
}

.newsSimpleItem .newsItem .img {
  width: 47.91667%;
  float: left;
  margin-right: 20px;
}

.newsSimpleItem .newsItem .img img {
  width: 100%;
  height: auto;
}

.newsSimpleItem .newsItem .newsInfo {
  overflow: hidden;
}

.newsSimpleItem .newsItem .name {
  font-size: 18px;
  margin: 0 0 20px;
}

.newsSimpleItem .newsItem .readMore {
  margin-top: 20px;
}

.mainLinks .stripeTitle {
  margin-bottom: 5px;
}

.mainLinks .inRow {
  margin-left: -10px;
  margin-right: -10px;
}

.mainLinks .inRow .item {
  padding-left: 10px;
  padding-right: 10px;
}

.mainLinks .img {
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  display: block;
}

.mainLinks .img img {
  width: 200%;
  height: auto;
  display: block;
  position: relative;
}

.mainLinks .img:hover img {
  left: -100%;
}

.mainLinks .readMore {
  margin-top: 5px;
}

#footer {
  border-top: 2px solid #e1e1e1;
  margin-top: 50px;
  background: #eee;
  padding: 30px 0 35px;
  color: #909090;
  overflow: hidden;
}

.footerBlocks {
  position: relative;
}

.footerBlocks .contactData {
  margin-right: 310px;
  overflow: hidden;
  min-height: 215px;
}

.footerBlocks .contactInfo {
  width: 55%;
  float: left;
}

.footerBlocks .contactInfo td, .footerBlocks .contactInfo p {
  font-size: 12px;
}

.footerBlocks .newsletter {
  width: 42%;
  float: right;
}

.footerBlocks .name {
  font-size: 16px;
  margin: 0 0 15px;
}

.footerBlocks .catalogue {
  position: absolute;
  top: -30px;
  right: 0;
  width: 295px;
  overflow: hidden;
}

.footerBlocks .catalogue > a {
  display: block;
  color: #646464;
  overflow: hidden;
}

.footerBlocks .catalogue > a:hover .orderCatalogue {
  background-position: 100% 0;
}

.footerBlocks .catalogue .orderCatalogue {
  display: block;
  background: url(../img/catalogue.jpg) no-repeat 0 0;
  width: 130px;
  height: 215px;
  float: left;
  margin: 0 10px 0 0;
}

.footerBlocks .catalogue .orderInfo {
  overflow: hidden;
}

.footerBlocks .catalogue .orderInfo p {
  font-size: 11px;
  line-height: 1.3;
}

.footerBlocks .catalogue .topInfo {
  padding: 30px 0 0 15px;
  font-size: 12px;
  margin-bottom: 5px;
}

.newsletterForm .field {
  max-width: 205px;
  overflow: hidden;
}

.newsletterForm .field .button {
  float: right;
  padding: 0 0 0 4px;
  width: 27px;
  height: 27px;
  text-align: center;
}

.newsletterForm .field .input {
  overflow: hidden;
}

.newsletterForm .field input {
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  border: none;
  height: 27px;
  line-height: 27px;
  padding: 0 10px;
}

.newsletterForm p {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.4;
}

.footerFinish {
  overflow: hidden;
  margin-top: -10px;
}

.footerFinish .slogan {
  float: left;
}

.footerFinish .map {
  overflow: hidden;
  float: right;
  margin-top: 5px;
}

.footerFinish .map .name {
  margin: 0 35px 0 0;
  font-size: 15px;
  font-size: 1.5rem;
  color: #000;
  font-weight: bold;
  float: left;
}

.footerFinish .map ul {
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style: none;
  cursor: default;
}

.footerFinish .map ul li {
  display: inline-block;
}

.footerFinish .map ul li:before {
  content: '|';
  margin: 0 5px;
}

.footerFinish .map ul li:first-child:before {
  display: none;
}

.aboutPage .moreInfo {
  padding-left: 10px;
  margin-top: 15px;
}

.aboutPage .morePages {
  margin-top: 40px;
}

.terraGroup .title {
  font-size: 24px;
  color: #91ba18;
}

.terraGroup .groupsList {
  margin-top: 15px;
  position: relative;
  padding-left: 135px;
  min-height: 70px;
}

.terraGroup .groupsList:before {
  content: ' ';
  display: block;
  background: url(../img/terra_group.png);
  width: 136px;
  height: 55px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -5px;
}

.terraGroup .listInner {
  overflow: hidden;
  overflow: hidden;
  margin: 0 -2px;
  font-size: 1px;
  line-height: 0;
}

.terraGroup .listInner .item {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  font-size: 1.3rem;
  line-height: 1.5;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.terraGroup .listInner.count_1 .item {
  width: 100%;
  padding: 0 2px;
}

.terraGroup .listInner.count_2 .item {
  width: 50%;
  padding: 0 2px;
}

.terraGroup .listInner.count_3 .item {
  width: 33.33333%;
  padding: 0 2px;
}

.terraGroup .listInner.count_4 .item {
  width: 25%;
  padding: 0 2px;
}

.terraGroup .listInner.count_5 .item {
  width: 20%;
  padding: 0 2px;
}

.terraGroup .listInner.count_6 .item {
  width: 16.66667%;
  padding: 0 2px;
}

.terraGroup .item .inner {
  width: 100%;
  overflow: hidden;
  display: block;
  max-width: 160px;
  margin: 0 auto;
}

.terraGroup .item img {
  width: 320px;
  height: auto;
  position: relative;
  left: -160px;
}

.terraGroup .item:hover img {
  left: 0;
}

.materialsIndex .colorsWrapper {
  margin-bottom: 10px;
}

.materialsIndex .info {
  text-align: justify;
  margin-bottom: 25px;
}

.materialsIndex .specialTitle {
  margin-top: 0;
}

.materialsIndex .materialsList {
  margin: 0 0 0 10px;
  max-width: 585px;
  position: relative;
}

.materialsIndex .materialsList:after {
  content: ' ';
  display: block;
  background: url(../img/materials.jpg);
  width: 399px;
  height: 741px;
  position: absolute;
  top: -10px;
  left: 605px;
}

.colorsGroupsList .colorGroup {
  margin-bottom: 40px;
}

.colorsGroupsList .colorGroup > .title {
  margin: 0 0 10px 10px;
  text-transform: uppercase;
  font-size: 14px;
  color: #3b3d3f;
}

.colorsList {
  overflow: hidden;
  margin: 0 -1px;
  font-size: 1px;
  line-height: 0;
}

.colorsList .colorItem {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  font-size: 1.3rem;
  line-height: 1.5;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.colorsList.count_1 .colorItem {
  width: 100%;
  padding: 0 1px;
}

.colorsList.count_2 .colorItem {
  width: 50%;
  padding: 0 1px;
}

.colorsList.count_3 .colorItem {
  width: 33.33333%;
  padding: 0 1px;
}

.colorsList.count_4 .colorItem {
  width: 25%;
  padding: 0 1px;
}

.colorsList.count_5 .colorItem {
  width: 20%;
  padding: 0 1px;
}

.colorsList.count_6 .colorItem {
  width: 16.66667%;
  padding: 0 1px;
}

.colorsList.count_7 .colorItem {
  width: 14.28571%;
  padding: 0 1px;
}

.colorsList.count_8 .colorItem {
  width: 12.5%;
  padding: 0 1px;
}

.colorsList .colorItem {
  vertical-align: top;
  text-align: center;
  margin-bottom: 20px;
}

.colorsList .imgInfo {
  background: #eeeef0;
  padding: 6px 5px 3px;
  margin-bottom: 2px;
}

.colorsList .img {
  max-width: 73px;
  margin: 0 auto 6px;
}

.colorsList .img:before {
  content: ' ';
  display: block;
  width: 100%;
  padding-top: 95.89041%;
  background: url(../img/color.png) no-repeat 0 0;
  background-size: 100% auto;
}

.colorsList .ral {
  color: #999;
  font-size: 12px;
}

.colorsList .name {
  background: #999;
  color: #fff;
  font-weight: bold;
  padding: 5px 5px 6px;
}

.materialsList .item {
  margin-bottom: 55px;
  overflow: hidden;
}

.materialsList .img {
  float: left;
  margin: 5px 40px 0 0;
}

.materialsList .materialInfo {
  overflow: hidden;
}

.materialsList .name {
  margin: 0 0 7px;
  font-size: 14px;
  text-transform: uppercase;
}

.materialsList .textContent {
  font-size: 12px;
  font-size: 1.2rem;
  text-align: justify;
}

.pageSpecial {
  padding-top: 30px;
}

.pageSpecial .specialContent {
  margin-bottom: 30px;
}

.accessoriesIndex .accessoryText {
  border-top: 1px solid #ccc;
  padding-top: 40px;
  margin-top: 30px;
}

.accessoriesIndex .accessoryText > .title {
  margin: 0 0 10px;
  text-transform: uppercase;
  font-size: 14px;
}

.accessoriesIndex .accessoryText:first-child {
  border-top: 0;
  padding-top: 0;
  margin-top: 0;
}

.specialPage {
  background: #4c4a4b;
  color: #fff;
  padding: 30px 0 40px;
}

.specialPage .specialWrapper {
  max-width: 880px;
}

.architectPage .specialPage img {
  max-width: 100%;
  height: auto;
}

.architectPage .item {
  margin-top: 40px;
}

.architectPage .item:first-child {
  margin-top: 25px;
}

.architectPage .name {
  font-size: 24px;
  margin: 0 0 20px;
}

.architectPage .name strong {
  color: #3b3d3f;
}

.architectPage .libraryLink {
  position: relative;
  top: -15px;
}

.contactIndex {
  position: relative;
  padding-top: 30px;
}

.contactIndex .contactPage {
  max-width: 630px;
  position: relative;
  min-height: 600px;
}

.contactIndex:after {
  content: ' ';
  display: block;
  background: url(../img/kontakt_razem.jpg);
  width: 342px;
  height: 638px;
  position: absolute;
  left: 700px;
  top: 10px;
}

.contactPage .info {
  width: 50%;
  float: left;
}

.contactPage .form {
  width: 43%;
  float: right;
}

.contactPage .formHead:first-line {
  font-size: 16px;
  font-weight: bold;
}

.contactPage .formContent {
  margin-top: 25px;
}

.contactCatalogue {
  max-width: 530px;
  margin-left: 85px;
  padding-top: 30px;
}

.contactCatalogue .textInfo .textContent {
  text-align: justify;
}

.contactCatalogue .blockName {
  margin: 20px 0;
  font-size: 20px;
  color: #91ba18;
}

.contactCatalogue .form label {
  width: 125px;
  margin-right: 10px;
  float: left;
  text-align: right;
}

.contactCatalogue .form .input {
  margin-bottom: 20px;
}

.contactCatalogue .form p {
  font-size: 10px;
  color: #999;
}

.contactCatalogue .form .submit {
  margin-top: 20px;
  overflow: hidden;
}

.contactCatalogue .form .submit .required {
  float: left;
  color: #91ba18;
  padding-top: 10px;
  font-size: 12px;
}

.categoriesBanners {
  height: 454px;
}

.categoriesBanners > .axis {
  height: 100%;
  position: relative;
}

.categoriesBanners .categoriesVList {
  width: 268px;
  float: left;
}

.categoriesBanners .img {
  overflow: hidden;
  height: 100%;
  position: relative;
}

.categoriesBanners .bannerName {
  position: absolute;
  top: 32px;
  right: 0;
}

.categoriesBanners .bannerName span {
  display: block;
  float: left;
  margin-left: 2px;
  background: #505050;
  color: #fff;
  height: 24px;
  line-height: 22px;
  padding: 0 15px;
  font-size: 14px;
}

.categoriesBanners .bannerName .cName {
  text-transform: uppercase;
  font-weight: bold;
}

.categoriesBanners .bannerName .pName {
  width: 140px;
}

.categoriesVList {
  height: 100%;
}

.categoriesVList .item {
  display: block;
  position: relative;
  white-space: nowrap;
  text-align: center;
  background: #669104;
  background: -moz-linear-gradient(left, #669104 0%, #7eaa17 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #669104), color-stop(100%, #7eaa17));
  background: -webkit-linear-gradient(left, #669104 0%, #7eaa17 100%);
  background: -o-linear-gradient(left, #669104 0%, #7eaa17 100%);
  background: -ms-linear-gradient(left, #669104 0%, #7eaa17 100%);
  background: linear-gradient(to right, #669104 0%, #7eaa17 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#669104', endColorstr='#7eaa17', GradientType=1);
}

.categoriesVList .item:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 1px;
  background: #8ab620;
}

.categoriesVList .item:first-child:before {
  display: none;
}

.categoriesVList .item:hover, .categoriesVList .item.current {
  background: #505052;
}

.categoriesVList .item:hover .info, .categoriesVList .item.current .info {
  display: inline-block;
}

.categoriesVList .inner {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -10px;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
}

.categoriesVList .inner .name {
  font-family: BariolB, Bariol, Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.categoriesVList .inner .info {
  display: none;
}

.categoriesVList.count_1 .item {
  height: 100%;
}

.categoriesVList.count_2 .item {
  height: 50%;
}

.categoriesVList.count_3 .item {
  height: 33.33333%;
}

.categoriesVList.count_4 .item {
  height: 25%;
}

.categoriesVList.count_5 .item {
  height: 20%;
}

.categoriesVList.count_6 .item {
  height: 16.66667%;
}

.categoriesVList.count_7 .item {
  height: 14.28571%;
}

.categoriesVList.count_8 .item {
  height: 12.5%;
}

.categoriesVList.count_9 .item {
  height: 11.11111%;
}

.categoriesVList.count_10 .item {
  height: 10%;
}

.categoriesView .cRow .left {
  float: left;
  width: 39.58333%;
  padding-left: 20px;
}

.categoriesView .cRow .right {
  float: right;
  width: 55.20833%;
}

.categoriesView .collectionHead {
  border-bottom: 1px solid #ccc;
}

.categoriesView .selected {
  margin-top: 3px;
}

.categoriesView .collectionInfo {
  margin-top: 25px;
}

.categoriesView .collectionInfo .collectionName {
  margin-top: 15px;
}

.categoriesView .collectionInfo .textContent {
  text-align: justify;
}

.simpleCatsList {
  text-align: justify;
  height: 38px;
  overflow: hidden;
  cursor: default;
}

.simpleCatsList .item {
  display: inline-block;
  margin: 0 3px;
  height: 34px;
  line-height: 34px;
  border-bottom: 4px solid transparent;
  padding: 0 5px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.simpleCatsList .item:hover {
  color: #91ba18;
}

.simpleCatsList .item.current {
  border-color: #91ba18;
}

.simpleCatsList:after {
  content: ' ';
  display: inline-block;
  width: 90%;
  height: 1px;
  overflow: hidden;
}

.selected {
  display: inline-block;
  padding-right: 45px;
  background: url(../img/selected.png) no-repeat right center;
  height: 24px;
  line-height: 24px;
  font-size: 17px;
  text-transform: uppercase;
}

.collectionName {
  font-size: 17px;
  text-transform: uppercase;
  color: #3b3d3f;
}

.simplePage {
  padding-top: 30px;
}

.brandInfo .img {
  margin-bottom: 20px;
}

.brandsInfoList {
  overflow: hidden;
  margin: 0 -10px;
  font-size: 1px;
  line-height: 0;
}

.brandsInfoList .item {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  font-size: 1.3rem;
  line-height: 1.5;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.brandsInfoList.count_1 .item {
  width: 100%;
  padding: 0 10px;
}

.brandsInfoList.count_2 .item {
  width: 50%;
  padding: 0 10px;
}

.brandsInfoList.count_3 .item {
  width: 33.33333%;
  padding: 0 10px;
}

.brandsInfoList.count_4 .item {
  width: 25%;
  padding: 0 10px;
}

.brandsInfoList .item {
  vertical-align: top;
  margin-bottom: 40px;
  position: relative;
}

.brandsInfoList .logo {
  height: 60px;
  line-height: 60px;
  margin-bottom: 5px;
}

.brandsInfoList .textContent {
  text-align: justify;
  margin-bottom: 15px;
}

.brandsInfoList .img {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 0;
}

.brandsInfoList .img a {
  display: block;
  width: 100%;
}

.brandsInfoList .img img {
  width: 100%;
  height: auto;
}

.brandsInfoList.count_2 .item.hasImage {
  padding-bottom: 17.34694%;
}

.productsIndex .catProdList {
  margin-top: -30px;
}

.catProdList {
  margin-left: -25px;
  margin-right: -25px;
}

.catProdList .categoryItem {
  margin-top: 20px;
  padding-top: 25px;
  position: relative;
}

.catProdList .categoryItem:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 1px;
  background: #ccc;
}

.catProdList .categoryItem.isDark .productsList .img {
  background: #000;
}

.catProdList .catName {
  margin: 0 25px 20px;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 20px;
  color: #3b3d3f;
}

.productsList {
  overflow: hidden;
  margin: 0 -10px;
  font-size: 1px;
  line-height: 0;
}

.productsList .prodItem {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  font-size: 1.3rem;
  line-height: 1.5;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.productsList.count_1 .prodItem {
  width: 100%;
  padding: 0 10px;
}

.productsList.count_2 .prodItem {
  width: 50%;
  padding: 0 10px;
}

.productsList.count_3 .prodItem {
  width: 33.33333%;
  padding: 0 10px;
}

.productsList.count_4 .prodItem {
  width: 25%;
  padding: 0 10px;
}

.productsList.count_5 .prodItem {
  width: 20%;
  padding: 0 10px;
}

.productsList.count_6 .prodItem {
  width: 16.66667%;
  padding: 0 10px;
}

.productsList .prodItem {
  margin-bottom: 20px;
  vertical-align: top;
}

.productsList .prodItem.hasDownloads .name {
  margin: 0 0 5px;
}

.productsList .wrapper {
  display: block;
  max-width: 120px;
  margin: 0 auto;
  color: #3b3d3f;
}

.productsList .wrapper:hover .img img {
  opacity: 1;
}

.productsList .img {
  height: 120px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.productsList .img img {
  width: 100%;
  height: auto;
  display: block;
  opacity: .6;
  -webkit-transition: opacity 500ms ease;
  -moz-transition: opacity 500ms ease;
  -ms-transition: opacity 500ms ease;
  -o-transition: opacity 500ms ease;
  transition: opacity 500ms ease;
}

.productsList .name {
  text-align: center;
  margin: 3px 0 0;
  font-size: 11px;
  font-size: 1.1rem;
}

.productsList .tag {
  background: #91ba18;
  color: #fff;
  font-style: italic;
  position: absolute;
  top: 5px;
  line-height: 1.2;
  padding: 0 0 2px;
  right: -33px;
  z-index: 50;
  width: 100px;
  text-align: center;
  transform: rotate(45deg);
}

.productsList .pDownloads {
  margin: 3px 0 5px;
}

.productsList .pDownloads ul {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.productsList .pDownloads li {
  display: block;
  line-height: 1.2;
}

.productsList .pDownloads a {
  display: inline-block;
  font-weight: bold;
  font-size: 11px;
  font-size: 1.1rem;
}

.productsList .pDownloads a:before {
  content: '+';
  margin-right: 4px;
}

.productsView .productViewItem {
  margin-top: -30px;
}

.productsView .categoriesView .categoryProducts {
  margin-top: 20px;
}

.productImagesList .item {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: url("../js/grayscale.svg#grayscale");
  filter: gray;
  opacity: .7;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

.productImagesList .item:hover {
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  -o-filter: none;
  filter: none;
  opacity: 1;
}

.productViewItem .productInfo {
  float: right;
  width: 65.10417%;
}

.productViewItem .productOtherInfo {
  float: left;
  width: 32.8125%;
}

.productViewItem .productOtherInfo .productDownloads {
  margin-top: 10px;
  position: relative;
  z-index: 30;
}

.productViewItem .productOtherInfo .fullImg {
  margin: -45px 0 0 -30px;
  position: relative;
  z-index: 20;
}

.productViewItem .productOtherInfo .fullImg img {
  max-width: 100%;
  height: auto;
}

.productViewItem .productOtherInfo .buyIt {
  padding-left: 17%;
  margin-bottom: 20px;
}

.productViewItem .productOtherInfo .buyIt .button {
  font-size: 16px;
  font-weight: bold;
}

.productViewItem .productOtherInfo .buyIt .fa {
  vertical-align: -5%;
}

.productViewItem .productBottomInfo {
  margin-top: 20px;
}

.productHead {
  background: #628c02;
  color: #fff;
}

.productHead .brand {
  float: left;
  height: 40px;
  line-height: 38px;
  width: 36%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 20px;
}

.productHead .brand a {
  display: inline-block;
}

.productHead .changeProduct {
  overflow: hidden;
}

.changeProduct {
  position: relative;
  height: 40px;
  line-height: 40px;
}

.changeProduct .prev,
.changeProduct .next {
  display: block;
  width: 80px;
  text-align: center;
  color: #fff;
  background: #567901;
  font-size: 22px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.changeProduct .prev:hover,
.changeProduct .next:hover {
  background: #505052;
  color: #fff;
}

.changeProduct .prev {
  float: left;
  border-right: 1px solid #91ba18;
}

.changeProduct .next {
  float: right;
  border-left: 1px solid #91ba18;
}

.changeProduct .productName {
  overflow: hidden;
  text-align: center;
  font-size: 14px;
  font-weight: bolder;
}

.productBottomInfo .textInfo {
  width: 36%;
  float: left;
  margin-right: 15px;
}

.productBottomInfo .specificationInfo {
  overflow: hidden;
}

.productBottomInfo .textContent {
  text-align: justify;
  margin-bottom: 25px;
  font-size: 11px;
  font-size: 1.1rem;
  line-height: 1.4;
}

.productBottomInfo .simpleTable {
  width: 100%;
}

.smallColorsList .colorGroup {
  margin-bottom: 30px;
}

.smallColorsList .ralInfo {
  margin-top: 5px;
  font-size: 11px;
  font-size: 1.1rem;
}

.smallColors {
  overflow: hidden;
  margin: 0 -1px;
  font-size: 1px;
  line-height: 0;
  overflow: visible;
}

.smallColors .item {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  font-size: 1.3rem;
  line-height: 1.5;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.smallColors.count_3 .item {
  width: 33.33333%;
  padding: 0 1px;
}

.smallColors .item {
  position: relative;
  margin-bottom: 2px;
  vertical-align: text-top;
  overflow: visible;
}

.smallColors .item:before {
  content: ' ';
  display: block;
  width: 100%;
  height: 0;
  padding-top: 100%;
  background: #eeeef0;
}

.smallColors .color {
  display: block;
  width: 70%;
  height: 70%;
  position: absolute;
  left: 15%;
  top: 15%;
  z-index: 20;
}

.smallColors .nr {
  position: absolute;
  left: 10%;
  bottom: 25%;
  z-index: 30;
  background: #3b3d3f;
  color: #fff;
  font-size: 9px;
  padding: 0 3px;
  border: 1px solid #646464;
}

.hasTitle {
  position: relative;
}

.hasTitle:after {
  content: attr(data-title);
  position: absolute;
  left: 10px;
  bottom: -17px;
  background: #fff;
  border: 1px solid #999;
  white-space: nowrap;
  z-index: 999;
  font-size: 11px;
  font-size: 1.1rem;
  color: #3b3d3f;
  padding: 8px;
  font-weight: bold;
  display: none;
}

.hasTitle:hover:after {
  display: block;
}

.technicalImg a {
  display: block;
  width: 100%;
}

.technicalImg a:hover .enlarge {
  color: #91ba18;
}

.technicalImg img {
  max-width: 100%;
  height: auto;
}

.technicalImg .enlarge {
  text-align: right;
  padding-right: 25px;
}

.enlarge {
  color: #567901;
  font-size: 11px;
  font-size: 1.1rem;
  font-weight: bold;
}

.enlarge .fa {
  margin-right: 5px;
}

.modelsInfo {
  margin-top: 10px;
}

.modelsInfo .infoItem {
  margin-bottom: 20px;
}

.modelsInfo .simpleTable a {
  text-transform: lowercase;
}

.goodToKnow {
  text-align: center;
  padding-bottom: 40px;
  position: relative;
}

.goodToKnow .item {
  display: inline-block;
  vertical-align: top;
  position: static;
}

.goodToKnow .hasTitle:after {
  left: 0;
  bottom: 0;
  right: 0;
}

.productDownloads {
  background: url(../img/download.png) no-repeat 0 0;
  width: 180px;
  padding: 50px 0 0 63px;
  overflow: hidden;
}

.productDownloads a {
  display: block;
  width: 42px;
  height: 64px;
  float: left;
  margin-right: 10px;
  background: url(../img/files.png) no-repeat 0 0;
  line-height: 64px;
  text-indent: 64px;
  overflow: hidden;
  opacity: .3;
  cursor: default;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.productDownloads a[href] {
  cursor: pointer;
  opacity: .6;
}

.productDownloads a[href]:hover {
  opacity: 1;
}

.productDownloads .filePng {
  background-position: 0 0;
}

.productDownloads .fileDwg {
  background-position: -42px 0;
}

.productDownloads .filePdf {
  background-position: -84px 0;
}

.searchForm {
  margin-top: -20px;
}

.searchForm .query {
  border-bottom: 1px solid #ccc;
  position: relative;
}

.searchForm .query .input {
  margin: 0 40px 0 0;
}

.searchForm .query .input input {
  border: none;
  font-size: 20px;
  color: #3b3d3f;
  height: 50px;
  line-height: 50px;
  outline: none;
  padding-left: 20px;
}

.searchForm .query ::-webkit-input-placeholder {
  opacity: .8 !important;
  color: #3b3d3f;
  text-transform: uppercase;
  font-style: italic;
}

.searchForm .query :-moz-placeholder {
  opacity: .8 !important;
  color: #3b3d3f;
  text-transform: uppercase;
  font-style: italic;
}

.searchForm .query ::-moz-placeholder {
  opacity: .8 !important;
  color: #3b3d3f;
  text-transform: uppercase;
  font-style: italic;
}

.searchForm .query :-ms-input-placeholder {
  opacity: .8 !important;
  color: #3b3d3f;
  text-transform: uppercase;
  font-style: italic;
}

.searchForm .query .clearForm {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 24px;
  height: 50px;
  line-height: 44px;
  cursor: pointer;
  color: #3b3d3f;
}

.searchForm .query .clearForm:hover {
  color: #91ba18;
}

.searchForm .advanced {
  margin: 40px 20px;
  color: #3b3d3f;
}

.searchForm .advanced .rightSubmit {
  float: right;
}

.searchForm .aRow {
  overflow: hidden;
  margin: 0 -25px;
  font-size: 1px;
  line-height: 0;
}

.searchForm .aRow .item {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  font-size: 1.3rem;
  line-height: 1.5;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.searchForm .aRow.count_1 .item {
  width: 100%;
  padding: 0 25px;
}

.searchForm .aRow.count_2 .item {
  width: 50%;
  padding: 0 25px;
}

.searchForm .aRow.count_3 .item {
  width: 33.33333%;
  padding: 0 25px;
}

.searchForm .aRow.count_4 .item {
  width: 25%;
  padding: 0 25px;
}

.searchForm .aRow.count_5 .item {
  width: 20%;
  padding: 0 25px;
}

.searchForm .aRow.count_6 .item {
  width: 16.66667%;
  padding: 0 25px;
}

.searchForm .aRow .item {
  vertical-align: top;
  margin-bottom: 20px;
}

.searchForm .title {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0 0 35px;
}

.searchForm .subTitle {
  margin: 30px 0 15px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.1;
  border-bottom: 2px solid #91ba18;
  float: left;
  clear: both;
}

.searchForm .subTitle:first-child {
  margin-top: 0;
}

.searchForm .spacer {
  margin-top: 20px;
}

.searchForm .finishRow {
  overflow: hidden;
  margin-top: 30px;
}

.searchForm .finishRow .tags {
  float: left;
  width: auto;
}

.searchForm .finishRow .niceInputs {
  overflow: hidden;
}

.searchForm .finishRow .niceInputs .input {
  float: left;
  margin-left: 100px;
}

.searchForm .finishRow .niceInputs .input:first-child {
  margin-left: 0;
}

.searchForm .submit .button {
  min-width: 200px;
}

.niceInputs {
  width: 100%;
  overflow: hidden;
  clear: both;
}

.niceInputs .input {
  margin-bottom: 8px;
}

.niceInputs .input input {
  display: none;
}

.niceInputs .input label {
  display: block;
  cursor: pointer;
  min-height: 20px;
  padding-left: 30px;
  font-size: 14px;
  line-height: 1.2;
  position: relative;
}

.niceInputs .input label:before, .niceInputs .input label:after {
  content: ' ';
  display: block;
  position: absolute;
}

.niceInputs .input label:before {
  width: 18px;
  height: 18px;
  border: 1px solid #ccc;
  left: 0;
  top: 0;
}

.niceInputs .input label:after {
  width: 12px;
  height: 12px;
  background: #50575f;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  left: 4px;
  top: 4px;
  opacity: 0;
}

.niceInputs .input label:hover:after {
  opacity: .5;
}

.niceInputs .input input:checked + label:after {
  opacity: 1;
}

.specialInputs {
  width: 100%;
  overflow: hidden;
  clear: both;
  cursor: default;
}

.specialInputs .input {
  display: inline-block;
  vertical-align: top;
  margin: 0 6px 12px 0;
}

.specialInputs .input input {
  display: none;
}

.specialInputs .input label {
  display: block;
  cursor: pointer;
  position: relative;
}

.specialInputs .input label:after {
  content: '\f00c';
  font-family: FontAwesome;
  font-size: 24px;
  color: #fff;
  text-shadow: 0 0 2px #000;
  display: block;
  position: absolute;
  right: -5px;
  top: -5px;
  opacity: 0;
}

.specialInputs .input label:hover:after {
  opacity: .5;
}

.specialInputs .input input:checked + label:after {
  opacity: 1;
}

.specialInputs .name {
  font-size: 11px;
  display: block;
  text-align: center;
  word-break: break-all;
  height: 16px;
  line-height: 1;
  text-transform: uppercase;
}

.colorInputs .input {
  width: 30px;
  height: 50px;
}

.colorInputs .color {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  display: block;
  border: 1px solid #ccc;
  margin-bottom: 7px;
}

.colorInputs .color:before {
  content: ' ';
  display: block;
  width: 100%;
  height: 0;
  padding-top: 100%;
}

.shapeInputs .input {
  width: 55px;
  height: 55px;
}

.shapeInputs .input label:after {
  right: 5px;
}

.shapeInputs .shape {
  display: block;
  width: 32px;
  margin: 0 auto 8px;
  height: 30px;
  background: url(../img/shapes.png) no-repeat 0 0;
}

.shapeInputs .shape_0 {
  background-position: 0 0;
}

.shapeInputs .shape_1 {
  background-position: -32px 0;
}

.shapeInputs .shape_2 {
  background-position: -64px 0;
}

.shapeInputs .shape_3 {
  background-position: -96px 0;
}

.searchResults {
  padding: 0 20px;
  margin-top: 35px;
}

html.darkLayout, html.darkLayout body {
  background: #070707;
  color: #fff;
}

html.darkLayout #header {
  background: #070707;
}

html.darkLayout #header .logo a {
  display: block;
  background: url(../img/logo_light.png) no-repeat 0 0;
}

html.darkLayout #header .logo img {
  opacity: 0;
}

html.darkLayout #header .mainMenu > ul > li > a {
  color: #fff;
}

html.darkLayout #header.overHeader {
  border-color: #3b3b3b;
}

html.darkLayout a {
  color: #fff;
}

html.darkLayout .collectionName {
  color: #fff;
}

html.darkLayout .productsList .wrapper {
  color: #fff;
}

html.darkLayout #footer {
  background: #070707;
  color: #fff;
  border-color: #3b3b3b;
}

html.darkLayout .footerBlocks .catalogue .orderInfo {
  color: #fff;
}

html.darkLayout .smallColors .item:before {
  background: #3d3d3f;
}

html.darkLayout .simpleTable td, html.darkLayout .simpleTable th {
  background: #3d3d3f;
  border-color: #070707;
}

html.darkLayout .productDownloads a {
  background-image: url(../img/files_light.png);
}

html.darkLayout .searchForm {
  color: #fff;
}

html.darkLayout .searchForm .advanced {
  color: #fff;
}

html.darkLayout .searchForm .query {
  background: #fff;
}

html.darkLayout .searchForm .query .clearForm {
  right: 5px;
}

html.darkLayout .catProdList .catName {
  color: #fff;
}

html.darkLayout .niceInputs .input label:after {
  background: #fff;
}

html.darkLayout .inverseImg img {
  margin: -40px 0 0;
}

html.darkLayout .inverseImg.footerSlogan img {
  margin: -37px 0 0;
}

html.darkLayout .inverseImg.footerOrder img {
  margin: -50px 0 0;
}

.searchPlace {
  overflow: hidden;
  position: relative;
  margin: 0 !important;
  padding: 0 !important;
}

.searchPlace .searchIndex {
  overflow: hidden;
  position: relative;
}

.searchPlace .banners {
  display: none;
}

.searchPlace .searchForm {
  margin-top: 0;
}

.inverseImg {
  display: inline-block;
  overflow: hidden;
}

.inverseImg img {
  display: block;
  margin: 0 0 -40px;
}

.inverseImg.footerSlogan img {
  margin: 0 0 -37px;
}

.inverseImg.footerOrder img {
  margin: 0 0 -50px;
}

.newsContent {
  overflow: hidden;
}

.newsContent .newsPageText {
  float: right;
  max-width: 300px;
  margin-left: 20px;
}

.newsContent .newsList {
  overflow: hidden;
}

.newsIndex .newsList {
  margin-top: 10px;
}

.newsList .newsItem {
  margin: 0 0 20px;
  overflow: hidden;
}

.newsList .name {
  margin: 0 0 5px;
  font-size: 24px;
}

.newsList .when {
  display: block;
  font-size: 13px;
  margin-bottom: 10px;
}

.newsList .img {
  margin: 10px 0;
}

.newsList .img img {
  max-width: 100%;
  height: auto;
}

.newsList .readMore {
  position: relative;
}

.newsList .readMore span {
  display: inline-block;
  background: #fff;
  padding-left: 15px;
  position: relative;
  z-index: 20;
}

.newsList .readMore:before {
  content: ' ';
  position: absolute;
  display: block;
  z-index: 15;
  left: 0;
  right: 0;
  top: 50%;
  border-top: 2px solid #e8e8e8;
  margin-top: 1px;
}

.paginator {
  margin: 30px 0 0;
  line-height: 30px;
}

.paginator a, .paginator span {
  display: inline-block;
  vertical-align: text-bottom;
  height: 30px;
}

.paginator .prev {
  margin-right: 10px;
}

.paginator .next {
  margin-left: 10px;
}

.paginator .number {
  width: 30px;
  text-align: center;
  margin: 0 2px;
}

.paginator .number a {
  display: block;
  background: #91ba18;
  color: #fff;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.paginator .number a:hover {
  background: #567901;
}

.prevNext {
  overflow: hidden;
}

.prevNext .prev {
  float: left;
}

.prevNext .next {
  float: right;
}

body.inSearch #header .nav .search .button {
  opacity: .3;
}

@charset "UTF-8";

@import "_variables";

a {
	@include transition(color);
}

.transition {
	@include transition();
}

html {
	background: #eee;
}

body {
	background: #fff;
}

.axis {
	margin: 0 auto;
	max-width: 100%;
	width: $width-axis;
}

#all {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.alert {
	margin: 10px 0 20px;
	color: #000;

	p {
		display: block;
		margin: 0 0 5px;
		padding: 8px 12px;
		text-align: center;
		color: #fff;
		font-weight: bold;
		text-transform: uppercase;
		font-size: 15px;
	}

	&.alert-success {
		p {
			background: $color-base;
		}
	}

	&.alert-error {
		p {
			background: red;
		}
	}
}

.simpleList {
	overflow: hidden;

	.item {
		display: inline-block;
		vertical-align: top;
	}
}

table {
	max-width: 100%;

	img {
		max-width: 100%;
		height: auto;
	}
}

#header {
	padding: 25px 0 20px;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	z-index: 500;
	background: #fff;

	.logo {
		float: left;
		margin-right: 30px;
	}

	.axis {
		//background: gray;
	}

	.search {
		float: right;
		margin-left: 20px;
	}

	.nav {
		margin-top: 8px;
	}

	.mainMenu {
		> ul {
			cursor: default;

			> li {
				display: block;
				float: left;
				margin-left: 2px;

				&:first-child {
					margin-left: 0;
				}

				> a {
					display: block;
					@include rem(font-size, 1.2rem);
					text-transform: uppercase;
					line-height: 1;
					padding: 13px 10px;
					position: relative;

					&:after {
						content: ' ';
						display: block;
						position: absolute;
						left: 0;
						right: 0;
						bottom: 0;
						height: 4px;
						@include transition();
						background: transparent;
					}

					&:hover {
						color: $color-text;
					}
				}

				&.current > a:after,
				> a.current:after,
				> a:hover:after {
					background: $color-base;
				}
			}
		}
	}

	.menuChanger,
	#menuChanger {
		display: none;
	}

	@media all and (min-width: $width-axis + 20px) {
		&.overHeader {
			position: fixed;
			border-bottom: 2px solid #e1e1e1;
			padding: 5px 0;
		}
	}
}

#main {
	margin-top: 97px;
}

.banners {
	background: url(../img/slider-bg.jpg) no-repeat center top;
	height: 492px;
	margin-bottom: 35px;

	.bannersList {
		height: 454px;
		position: relative;
		overflow: hidden;
	}

	.banner {
		position: absolute;
		width: 100%;
		height: 100%;

		> img {
			display: block;
			width: 100%;
		}

		.textContent {
			position: absolute;
			left: 30px;
			top: 294px;
			width: 375px;
			min-height: 140px;
			padding: 14px 15px 14px 10px;
			@include background-opacity(#fff, .6);
			@include prefix(box-sizing, border-box);

			.name {
				margin: 0 0 10px;
				font-size: 20px;
			}

			p {
				@include rem(font-size, 1.1rem);
				line-height: 1.3;
			}
		}
	}

	.pager {
		position: absolute;
		z-index: 300;
		cursor: default;

		span {
			display: block;
			float: left;
			width: 10px;
			height: 10px;
			margin-left: 5px;
			border-radius: 5px;
			overflow: hidden;
			text-indent: 10px;
			line-height: 10px;
			background: #fff;
			@include transition();
			cursor: pointer;

			&:hover {
				background: lighten($color-base, 15%);
			}

			&.cycle-pager-active {
				background: $color-base;
			}
		}
	}

	.arrows {
		position: absolute;
		z-index: 400;
	}
}

.layoutBanners {
	.arrows {
		top: 294px;
		left: 406px;
		width: 20px;

		.prev {
			margin-bottom: 1px;
		}
	}

	.pager {
		right: 35px;
		bottom: 25px;
	}
}

.arrows {
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;

	> div {
		text-align: center;
		cursor: pointer;
		width: 30px;
		height: 30px;
		line-height: 30px;
		margin-bottom: 4px;
		@include prefix(box-sizing, border-box);
		@include transition();

		&:before {
			font-family: FontAwesome;
			font-size: 20px;
			display: inline-block;
			vertical-align: middle;
			line-height: 1;
		}

		&:hover {
			color: $color-base;
		}
	}

	.prev {
		padding-right: 3px;

		&:before {
			content: '\f053';
		}
	}

	.next {
		&:before {
			content: '\f054';
		}
	}

	&.fullArrows {
		> div {
			width: 20px;
			height: 20px;
			padding: 0;
			display: inline-block;
			line-height: 16px;
			color: #fff;

			&:before {
				font-size: 10px;
			}
		}

		.prev {
			padding-right: 1px;
		}

		.next {
			padding-left: 1px;
		}
	}
}

.categoriesInLine {
	overflow: hidden;

	a {
		display: block;
		float: left;
		height: 38px;
		line-height: 38px;
		text-align: center;
		@include prefix(box-sizing, border-box);
		background: $color-base;
		color: #fff;
		font-size: 16px;
		font-weight: bold;
		text-transform: uppercase;
		border-left: 1px solid #7ea60a;
		border-right: 1px solid #bde644;
		padding: 0;

		&:first-child {
			border-left: 0;
		}

		&:last-child {
			border-right: 0;
		}
	}

	@for $i from 1 through $max-categories {
		&.count_#{$i} {
			a {
				width: (100% / $i);
			}
		}
	}
}

.mainBlocks {
	.item {
		margin-bottom: 30px;
	}

	.head {
		margin-bottom: 15px;

		.img {
			float: left;
			position: relative;
			height: 45px;
			margin-right: 5px;

			img {
				display: block;
				position: relative;
				left: -3px;
			}
		}

		.name {
			font-size: 18px;
			font-weight: bold;
			line-height: 1.2;
		}
	}

	.textContent {
		@include rem(font-size, 1.3rem);
		line-height: 1.3;
		@include rem(min-height, 1.3rem * 1.3 * 8);
	}
}

.readMore {
	text-align: right;

	a {
		display: inline-block;
		position: relative;
		height: 18px;
		line-height: 15px;
		padding-left: 25px;
		@include transition(color);

		&:before {
			content: '\f054';
			font-family: FontAwesome;
			font-size: 10px;
			width: 14px;
			padding-left: 2px;
			height: 16px;
			line-height: 16px;
			text-align: center;
			border: 1px solid $color-text;
			border-radius: 9px;
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			transform: translateX(0);
			@include prefix(transition, (border-color 500ms ease, transform 500ms ease));
		}

		&:hover {
			&:before {
				border-color: $color-base;
				transform: translateX(5px);
			}
		}
	}
}

.mainIndex {
	> section {
		margin-top: 30px;
		margin-bottom: 30px;
	}

	.mainBlock {
		margin-bottom: 0;
	}

	.newsSimpleItem {
		margin-top: 50px;
	}
}

.actionList {
	position: relative;

	.arrows {
		display: none;
		height: 0;

		> div {
			display: block;
			position: absolute;
			top: 50%;
			margin-top: -15px;
		}

		.prev {
			left: 0;
		}

		.next {
			right: 0;
		}
	}

	.slide {
		text-align: justify;
		line-height: 1px;
		font-size: 1px;

		&:after {
			content: ' ';
			display: inline-block;
			width: 95%;
			height: 1px;
			overflow: hidden;
			vertical-align: top;
		}
	}

	.item {
		display: inline-block;
		vertical-align: middle;
	}

	&.working {
		text-align: left;
		padding-left: 50px;
		padding-right: 50px;

		.arrows {
			display: block;
		}

		.slide {
			text-align: left;

			&:after {
				display: none;
			}
		}
	}
}

.brandsList {
	margin: 30px 0;

	.item {
		display: inline-block;
		height: 45px;
		line-height: 45px;
		@include opacity(.4);
		@include transition();

		img {
			margin: 0 5px;
		}

		&:hover {
			@include opacity(.2);
		}
	}

	&.working {
		.item {
			img {
				margin: 0;
			}
		}
	}
}

.newsSimpleItem {
	.newsItem {
		overflow: hidden;

		.img {
			width: 460 / 960 * 100%;
			float: left;
			margin-right: 20px;

			img {
				width: 100%;
				height: auto;
			}
		}

		.newsInfo {
			overflow: hidden;
		}

		.name {
			font-size: 18px;
			margin: 0 0 20px;
		}

		.textContent {

		}

		.readMore {
			margin-top: 20px;
		}
	}
}

.mainLinks {
	.stripeTitle {
		margin-bottom: 5px;
	}

	.inRow {
		margin-left: -10px;
		margin-right: -10px;

		.item {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	.img {
		max-width: 300px;
		width: 100%;
		margin: 0 auto;
		overflow: hidden;
		display: block;

		img {
			width: 200%;
			height: auto;
			display: block;
			position: relative;
		}

		&:hover {
			img {
				left: -100%;
			}
		}
	}

	.readMore {
		margin-top: 5px;
	}
}

#footer {
	border-top: 2px solid #e1e1e1;
	margin-top: 50px;
	background: #eee;
	padding: 30px 0 35px;
	color: #909090;
	overflow: hidden;
}

.footerBlocks {
	position: relative;

	.contactData {
		margin-right: 310px;
		overflow: hidden;
		min-height: 215px;
	}

	.contactInfo {
		width: 55%;
		float: left;

		td, p {
			font-size: 12px;
		}
	}

	.newsletter {
		width: 42%;
		float: right;
	}

	.name {
		font-size: 16px;
		margin: 0 0 15px;
	}

	.catalogue {
		position: absolute;
		top: -30px;
		right: 0;
		width: 295px;
		overflow: hidden;

		> a {
			display: block;
			color: $color-text;
			overflow: hidden;

			&:hover {
				.orderCatalogue {
					background-position: 100% 0;
				}
			}
		}

		.orderCatalogue {
			display: block;
			background: url(../img/catalogue.jpg) no-repeat 0 0;
			width: 130px;
			height: 215px;
			float: left;
			margin: 0 10px 0 0;
		}

		.orderInfo {
			overflow: hidden;

			p {
				font-size: 11px;
				line-height: 1.3;
			}
		}

		.topInfo {
			padding: 30px 0 0 15px;
			font-size: 12px;
			margin-bottom: 5px;
		}
	}
}

.newsletterForm {
	.field {
		max-width: 205px;
		overflow: hidden;

		.button {
			float: right;
			padding: 0 0 0 4px;
			width: 27px;
			height: 27px;
			text-align: center;
		}

		.input {
			overflow: hidden;
		}

		input {
			display: block;
			@include prefix(box-sizing, border-box);
			width: 100%;
			border: none;
			height: 27px;
			line-height: 27px;
			padding: 0 10px;
		}
	}

	p {
		@include rem(font-size, 1.2rem);
		line-height: 1.4;
	}
}

.footerFinish {
	overflow: hidden;
	margin-top: -10px;

	.slogan {
		float: left;
	}

	.map {
		overflow: hidden;
		float: right;
		margin-top: 5px;

		.name {
			margin: 0 35px 0 0;
			@include rem(font-size, 1.5rem);
			color: #000;
			font-weight: bold;
			float: left;
		}

		ul {
			margin: 0;
			padding: 0;
			overflow: hidden;
			list-style: none;
			cursor: default;

			li {
				display: inline-block;

				&:before {
					content: '|';
					margin: 0 5px;
				}

				&:first-child {
					&:before {
						display: none;
					}
				}
			}
		}
	}
}

.aboutPage {
	.moreInfo {
		padding-left: 10px;
		margin-top: 15px;
	}

	.morePages {
		margin-top: 40px;
	}
}

.terraGroup {
	.title {
		font-size: 24px;
		color: $color-base;
	}

	.groupsList {
		margin-top: 15px;
		position: relative;
		padding-left: 135px;
		min-height: 70px;

		&:before {
			content: ' ';
			display: block;
			background: url(../img/terra_group.png);
			width: 136px;
			height: 55px;
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -5px;
		}
	}

	.listInner {
		overflow: hidden;

		@include inRow(2px, '.item', 1, 6);
	}

	.item {
		.inner {
			width: 100%;
			overflow: hidden;
			display: block;
			max-width: 160px;
			margin: 0 auto;
		}

		img {
			width: 320px;
			height: auto;
			position: relative;
			left: -160px;
		}

		&:hover {
			img {
				left: 0;
			}
		}
	}
}

.materialsIndex {
	.colorsWrapper {
		margin-bottom: 10px;
	}

	.info {
		text-align: justify;
		margin-bottom: 25px;
	}

	.specialTitle {
		margin-top: 0;
	}

	.materialsList {
		margin: 0 0 0 10px;
		max-width: 585px;
		position: relative;

		&:after {
			content: ' ';
			display: block;
			background: url(../img/materials.jpg);
			width: 399px;
			height: 741px;
			position: absolute;
			top: -10px;
			left: 605px;
		}
	}
}

.colorsGroupsList {
	.colorGroup {
		margin-bottom: 40px;

		> .title {
			margin: 0 0 10px 10px;
			text-transform: uppercase;
			font-size: 14px;
			color: $color-dark;
		}
	}
}

.colorsList {
	@include inRow(1px, '.colorItem', 1, 8);

	.colorItem {
		vertical-align: top;
		text-align: center;
		margin-bottom: 20px;
	}

	.imgInfo {
		background: #eeeef0;
		padding: 6px 5px 3px;
		margin-bottom: 2px;
	}

	.img {
		max-width: 73px;
		margin: 0 auto 6px;

		&:before {
			content: ' ';
			display: block;
			width: 100%;
			padding-top: (70 / 73 * 100%);
			background: url(../img/color.png) no-repeat 0 0;
			background-size: 100% auto;
		}
	}

	.ral {
		color: #999;
		font-size: 12px;
	}

	.name {
		background: #999;
		color: #fff;
		font-weight: bold;
		padding: 5px 5px 6px;
	}
}

.materialsList {
	.item {
		margin-bottom: 55px;
		overflow: hidden;
	}

	.img {
		float: left;
		margin: 5px 40px 0 0;
	}

	.materialInfo {
		overflow: hidden;
	}

	.name {
		margin: 0 0 7px;
		font-size: 14px;
		text-transform: uppercase;
	}

	.textContent {
		@include rem(font-size, 1.2rem);
		text-align: justify;
	}
}

.pageSpecial {
	padding-top: 30px;

	.specialContent {
		margin-bottom: 30px;
	}
}

.accessoriesIndex {
	.accessoryText {
		border-top: 1px solid $color-light;
		padding-top: 40px;
		margin-top: 30px;

		> .title {
			margin: 0 0 10px;
			text-transform: uppercase;
			font-size: 14px;
		}

		&:first-child {
			border-top: 0;
			padding-top: 0;
			margin-top: 0;
		}
	}
}

.specialPage {
	background: #4c4a4b;
	color: #fff;
	padding: 30px 0 40px;

	.specialWrapper {
		max-width: 880px;
	}
}

.architectPage {
	.specialPage {
		img {
			max-width: 100%;
			height: auto;
		}
	}

	.item {
		margin-top: 40px;

		&:first-child {
			margin-top: 25px;
		}
	}

	.name {
		font-size: 24px;
		margin: 0 0 20px;

		strong {
			color: $color-dark;
		}
	}

	.libraryLink {
		position: relative;
		top: -15px;
	}
}

.contactIndex {
	position: relative;
	padding-top: 30px;

	.contactPage {
		max-width: 630px;
		position: relative;
		min-height: 600px;
	}

	&:after {
		content: ' ';
		display: block;
		background: url(../img/kontakt_razem.jpg);
		width: 342px;
		height: 638px;
		position: absolute;
		left: 700px;
		top: 10px;
	}
}

.contactPage {
	.info {
		width: 50%;
		float: left;
	}

	.form {
		width: 43%;
		float: right;
	}

	.formHead {
		&:first-line {
			font-size: 16px;
			font-weight: bold;
		}
	}

	.formContent {
		margin-top: 25px;
	}
}

.contactCatalogue {
	max-width: 530px;
	margin-left: 85px;
	padding-top: 30px;

	.textInfo {
		.textContent {
			text-align: justify;
		}
	}

	.blockName {
		margin: 20px 0;
		font-size: 20px;
		color: $color-base;
	}

	.form {
		label {
			width: 125px;
			margin-right: 10px;
			float: left;
			text-align: right;
		}

		.input {
			margin-bottom: 20px;
		}

		p {
			font-size: 10px;
			color: #999;
		}

		.submit {
			margin-top: 20px;
			overflow: hidden;

			.required {
				float: left;
				color: $color-base;
				padding-top: 10px;
				font-size: 12px;
			}
		}
	}
}

.categoriesBanners {
	height: 454px;

	> .axis {
		height: 100%;
		position: relative;
	}

	.categoriesVList {
		width: 268px;
		float: left;
	}

	.img {
		overflow: hidden;
		height: 100%;
		position: relative;
	}

	.bannerName {
		position: absolute;
		top: 32px;
		right: 0;

		span {
			display: block;
			float: left;
			margin-left: 2px;
			background: #505050;
			color: #fff;
			height: 24px;
			line-height: 22px;
			padding: 0 15px;
			font-size: 14px;
		}

		.cName {
			text-transform: uppercase;
			font-weight: bold;
		}

		.pName {
			width: 140px;
		}
	}
}

.categoriesVList {
	height: 100%;

	.item {
		display: block;
		position: relative;
		white-space: nowrap;
		text-align: center;

		background: rgb(102, 145, 4);
		background: -moz-linear-gradient(left, rgba(102, 145, 4, 1) 0%, rgba(126, 170, 23, 1) 100%);
		background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(102, 145, 4, 1)), color-stop(100%, rgba(126, 170, 23, 1)));
		background: -webkit-linear-gradient(left, rgba(102, 145, 4, 1) 0%, rgba(126, 170, 23, 1) 100%);
		background: -o-linear-gradient(left, rgba(102, 145, 4, 1) 0%, rgba(126, 170, 23, 1) 100%);
		background: -ms-linear-gradient(left, rgba(102, 145, 4, 1) 0%, rgba(126, 170, 23, 1) 100%);
		background: linear-gradient(to right, rgba(102, 145, 4, 1) 0%, rgba(126, 170, 23, 1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#669104', endColorstr='#7eaa17', GradientType=1);

		&:before {
			content: ' ';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			height: 1px;
			background: #8ab620;
		}

		&:first-child {
			&:before {
				display: none;
			}
		}

		&:hover,
		&.current {
			background: $color-alt-highlight;

			.info {
				display: inline-block;
			}
		}
	}

	.inner {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		margin-top: -10px;
		text-transform: uppercase;
		color: #fff;
		font-size: 14px;

		.name {
			font-family: BariolB, Bariol, Helvetica, Arial, sans-serif;
			font-weight: bold;
		}

		.info {
			display: none;
		}
	}

	@for $i from 1 through 10 {
		&.count_#{$i} {
			.item {
				height: (100% / $i);
			}
		}
	}
}

.categoriesView {
	.cRow {
		.left {
			float: left;
			width: (($width-axis - 530px - 50px) / $width-axis * 100%);
			padding-left: 20px;
		}

		.right {
			float: right;
			width: (530px / $width-axis * 100%);
		}
	}

	.collectionHead {
		border-bottom: 1px solid $color-light;
	}

	.selected {
		margin-top: 3px;
	}

	.collectionInfo {
		margin-top: 25px;

		.collectionName {
			margin-top: 15px;
		}

		.textContent {
			text-align: justify;
		}
	}
}

.simpleCatsList {
	text-align: justify;
	height: 38px;
	overflow: hidden;
	cursor: default;

	.item {
		display: inline-block;
		margin: 0 3px;
		height: 34px;
		line-height: 34px;
		border-bottom: 4px solid transparent;
		padding: 0 5px;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
		@include transition();

		&:hover {
			color: $color-base;
		}

		&.current {
			border-color: $color-base;
		}
	}

	&:after {
		content: ' ';
		display: inline-block;
		width: 90%;
		height: 1px;
		overflow: hidden;
	}
}

.selected {
	display: inline-block;
	padding-right: 45px;
	background: url(../img/selected.png) no-repeat right center;
	height: 24px;
	line-height: 24px;
	font-size: 17px;
	text-transform: uppercase;
}

.collectionName {
	font-size: 17px;
	text-transform: uppercase;
	color: $color-dark;
}

.simplePage {
	padding-top: 30px;
}

.brandInfo {
	.img {
		margin-bottom: 20px;
	}
}

.brandsInfoList {
	@include inRow(10px, '.item', 1, 4);

	.item {
		vertical-align: top;
		margin-bottom: 40px;
		position: relative;
	}

	.logo {
		height: 60px;
		line-height: 60px;
		margin-bottom: 5px;
	}

	.textContent {
		text-align: justify;
		margin-bottom: 15px;
	}

	.img {
		position: absolute;
		left: 10px;
		right: 10px;
		bottom: 0;

		a {
			display: block;
			width: 100%;
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	&.count_2 {
		.item.hasImage {
			padding-bottom: (170px / ($width-axis + 20px) * 100%);
		}
	}
}

.productsIndex {
	.catProdList {
		margin-top: -30px;
	}
}

.catProdList {
	margin-left: -25px;
	margin-right: -25px;

	.categoryItem {
		margin-top: 20px;
		padding-top: 25px;
		position: relative;

		&:before {
			content: ' ';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			height: 1px;
			background: $color-light;
		}

		&.isDark {
			.productsList {
				.img {
					background: #000;
				}
			}
		}
	}

	.catName {
		margin: 0 25px 20px;
		text-transform: uppercase;
		font-weight: normal;
		font-size: 20px;
		color: $color-dark;
	}
}

.productsList {
	@include inRow(10px, '.prodItem', 1, 6);

	.prodItem {
		margin-bottom: 20px;
		vertical-align: top;

		&.hasDownloads {
			.name {
				margin: 0 0 5px;
			}
		}
	}

	.wrapper {
		display: block;
		max-width: 120px;
		margin: 0 auto;
		color: $color-dark;

		&:hover {
			.img {
				img {
					opacity: 1;
				}
			}
		}
	}

	.img {
		height: 120px;
		margin: 0 auto;
		position: relative;
		overflow: hidden;

		img {
			width: 100%;
			height: auto;
			display: block;
			opacity: .6;
			@include transition(opacity);
		}
	}

	.name {
		text-align: center;
		margin: 3px 0 0;
		@include rem(font-size, 1.1rem);
	}

	.tag {
		background: $color-base;
		color: #fff;
		font-style: italic;
		position: absolute;
		top: 5px;
		line-height: 1.2;
		padding: 0 0 2px;
		right: -33px;
		z-index: 50;
		width: 100px;
		text-align: center;
		transform: rotate(45deg);
	}

	.pDownloads {
		margin: 3px 0 5px;

		ul {
			display: block;
			margin: 0;
			padding: 0;
			list-style: none;
			text-align: center;
		}

		li {
			display: block;
			line-height: 1.2;
		}

		a {
			display: inline-block;
			font-weight: bold;
			@include rem(font-size, 1.1rem);

			&:before {
				content: '+';
				margin-right: 4px;
			}
		}
	}
}

.productsView {
	.productViewItem {
		margin-top: -30px;
	}

	.categoriesView {
		.categoryProducts {
			margin-top: 20px;
		}
	}
}

.productImagesList {
	.item {
		@include grayScale(100%);
		opacity: .7;
		@include transition();
		@include prefix(transform, translateZ(0));

		&:hover {
			@include prefix(filter, none);
			opacity: 1;
		}
	}
}

.productViewItem {
	.productInfo {
		float: right;
		width: (625px / $width-axis) * 100%;
	}

	.productOtherInfo {
		float: left;
		width: (315px / $width-axis) * 100%;

		.productDownloads {
			margin-top: 10px;
			position: relative;
			z-index: 30;
		}

		.fullImg {
			margin: -45px 0 0 -30px;
			position: relative;
			z-index: 20;

			img {
				max-width: 100%;
				height: auto;
			}
		}

		.buyIt {
			padding-left: 17%;
			margin-bottom: 20px;

			.button {
				font-size: 16px;
				font-weight: bold;
			}

			.fa {
				vertical-align: -5%;
			}
		}
	}

	.productBottomInfo {
		margin-top: 20px;
	}
}

.productHead {
	background: #628c02;
	color: #fff;

	.brand {
		float: left;
		height: 40px;
		line-height: 38px;
		width: (225px / 625px) * 100%;
		@include prefix(box-sizing, border-box);
		padding: 0 20px;

		a {
			display: inline-block;
		}
	}

	.changeProduct {
		overflow: hidden;
	}
}

.changeProduct {
	position: relative;
	height: 40px;
	line-height: 40px;

	.prev,
	.next {
		display: block;
		width: 80px;
		text-align: center;
		color: #fff;
		background: $color-base-alt;
		font-size: 22px;
		@include transition();

		&:hover {
			background: $color-alt-highlight;
			color: #fff;
		}
	}

	.prev {
		float: left;
		border-right: 1px solid $color-base;
	}

	.next {
		float: right;
		border-left: 1px solid $color-base;
	}

	.productName {
		overflow: hidden;
		text-align: center;
		font-size: 14px;
		font-weight: bolder;
	}
}

.productBottomInfo {
	.textInfo {
		width: (225px / 625px) * 100%;
		float: left;
		margin-right: 15px;
	}

	.specificationInfo {
		overflow: hidden;
	}

	.textContent {
		text-align: justify;
		margin-bottom: 25px;
		@include rem(font-size, 1.1rem);
		line-height: 1.4;
	}

	.simpleTable {
		width: 100%;
	}
}

.smallColorsList {
	.colorGroup {
		margin-bottom: 30px;
	}

	.ralInfo {
		margin-top: 5px;
		@include rem(font-size, 1.1rem);
	}
}

.smallColors {
	@include inRow(1px, '.item', 3, 3);
	overflow: visible;

	.item {
		position: relative;
		margin-bottom: 2px;
		vertical-align: text-top;
		overflow: visible;

		&:before {
			content: ' ';
			display: block;
			width: 100%;
			height: 0;
			padding-top: 100%;
			background: #eeeef0;
		}
	}

	.color {
		display: block;
		width: 70%;
		height: 70%;
		position: absolute;
		left: 15%;
		top: 15%;
		z-index: 20;
	}

	.nr {
		position: absolute;
		left: 10%;
		bottom: 25%;
		z-index: 30;
		background: $color-dark;
		color: #fff;
		font-size: 9px;
		padding: 0 3px;
		border: 1px solid $color-text;
	}
}

.hasTitle {
	position: relative;

	&:after {
		content: attr(data-title);
		position: absolute;
		left: 10px;
		bottom: -17px;
		background: #fff;
		border: 1px solid #999;
		white-space: nowrap;
		z-index: 999;
		@include rem(font-size, 1.1rem);
		color: $color-dark;
		padding: 8px;
		font-weight: bold;
		display: none;
	}

	&:hover {
		&:after {
			display: block;
		}
	}
}

.technicalImg {
	a {
		display: block;
		width: 100%;

		&:hover {
			.enlarge {
				color: $color-base;
			}
		}
	}

	img {
		max-width: 100%;
		height: auto;
	}

	.enlarge {
		text-align: right;
		padding-right: 25px;
	}
}

.enlarge {
	color: $color-base-alt;
	@include rem(font-size, 1.1rem);
	font-weight: bold;

	.fa {
		margin-right: 5px;
	}
}

.modelsInfo {
	margin-top: 10px;

	.infoItem {
		margin-bottom: 20px;
	}

	.simpleTable {
		a {
			text-transform: lowercase;
		}
	}
}

.goodToKnow {
	text-align: center;
	padding-bottom: 40px;
	position: relative;

	.item {
		display: inline-block;
		vertical-align: top;
		position: static;
	}

	.hasTitle {
		&:after {
			left: 0;
			bottom: 0;
			right: 0;
		}
	}
}

.productDownloads {
	background: url(../img/download.png) no-repeat 0 0;
	width: 180px;
	padding: 50px 0 0 63px;
	overflow: hidden;

	a {
		display: block;
		width: 42px;
		height: 64px;
		float: left;
		margin-right: 10px;
		background: url(../img/files.png) no-repeat 0 0;
		line-height: 64px;
		text-indent: 64px;
		overflow: hidden;
		opacity: .3;
		cursor: default;
		@include transition;

		&[href] {
			cursor: pointer;
			opacity: .6;

			&:hover {
				opacity: 1;
			}
		}
	}

	.filePng {
		background-position: 0 0;
	}
	.fileDwg {
		background-position: -42px 0;
	}
	.filePdf {
		background-position: -84px 0;
	}
}

.searchForm {
	margin-top: -20px;

	.query {
		border-bottom: 1px solid $color-light;
		position: relative;

		.input {
			margin: 0 40px 0 0;

			input {
				border: none;
				font-size: 20px;
				color: $color-dark;
				height: 50px;
				line-height: 50px;
				outline: none;
				padding-left: 20px;
			}
		}

		@each $placeholder in (':-webkit-input-placeholder', -moz-placeholder, ':-moz-placeholder', -ms-input-placeholder) {
			:#{$placeholder} {
				opacity: .8 !important;
				color: $color-dark;
				text-transform: uppercase;
				font-style: italic;
			}
		}

		.clearForm {
			position: absolute;
			right: 0;
			top: 0;
			font-size: 24px;
			height: 50px;
			line-height: 44px;
			cursor: pointer;
			color: $color-dark;

			&:hover {
				color: $color-base;
			}
		}
	}

	.advanced {
		margin: 40px 20px;
		color: $color-dark;

		.rightSubmit {
			float: right;
		}
	}

	.aRow {
		@include inRow(25px, '.item', 1, 6);

		.item {
			vertical-align: top;
			margin-bottom: 20px;
		}
	}

	.title {
		font-size: 20px;
		text-transform: uppercase;
		font-weight: normal;
		margin: 0 0 35px;
	}

	.subTitle {
		margin: 30px 0 15px;
		font-size: 16px;
		font-weight: bold;
		text-transform: uppercase;
		line-height: 1.1;
		border-bottom: 2px solid $color-base;
		float: left;
		clear: both;

		&:first-child {
			margin-top: 0;
		}
	}

	.spacer {
		margin-top: 20px;
	}

	.finishRow {
		overflow: hidden;
		margin-top: 30px;

		.tags {
			float: left;
			width: auto;
		}

		.niceInputs {
			overflow: hidden;

			.input {
				float: left;
				margin-left: 100px;

				&:first-child {
					margin-left: 0;
				}
			}
		}
	}

	.submit {
		.button {
			min-width: 200px;
		}
	}
}

.niceInputs {
	width: 100%;
	overflow: hidden;
	clear: both;

	.input {
		margin-bottom: 8px;

		input {
			display: none;
		}

		label {
			display: block;
			cursor: pointer;
			min-height: 20px;
			padding-left: 30px;
			font-size: 14px;
			line-height: 1.2;
			position: relative;

			&:before,
			&:after {
				content: ' ';
				display: block;
				position: absolute;
			}

			&:before {
				width: 18px;
				height: 18px;
				border: 1px solid $color-light;
				left: 0;
				top: 0;
			}

			&:after {
				width: 12px;
				height: 12px;
				background: #50575f;
				@include transition();
				left: 4px;
				top: 4px;
				opacity: 0;
			}

			&:hover {
				&:after {
					opacity: .5;
				}
			}
		}

		input:checked + label {
			&:after {
				opacity: 1;
			}
		}
	}
}

.specialInputs {
	width: 100%;
	overflow: hidden;
	clear: both;
	cursor: default;

	.input {
		display: inline-block;
		vertical-align: top;
		margin: 0 6px 12px 0;

		input {
			display: none;
		}

		label {
			display: block;
			cursor: pointer;
			position: relative;

			&:after {
				content: '\f00c';
				font-family: FontAwesome;
				font-size: 24px;
				color: #fff;
				text-shadow: 0 0 2px #000;
				display: block;
				position: absolute;
				right: -5px;
				top: -5px;
				opacity: 0;
			}

			&:hover {
				&:after {
					opacity: .5;
				}
			}
		}

		input:checked + label {
			&:after {
				opacity: 1;
			}
		}
	}

	.name {
		font-size: 11px;
		display: block;
		text-align: center;
		word-break: break-all;
		height: 16px;
		line-height: 1;
		text-transform: uppercase;
	}
}

.colorInputs {
	.input {
		width: 30px;
		height: 50px;
	}

	.color {
		@include prefix(box-sizing, border-box);
		width: 100%;
		display: block;
		border: 1px solid $color-light;
		margin-bottom: 7px;

		&:before {
			content: ' ';
			display: block;
			width: 100%;
			height: 0;
			padding-top: 100%;
		}
	}
}

.shapeInputs {
	.input {
		width: 55px;
		height: 55px;

		label {
			&:after {
				right: 5px;
			}
		}
	}

	.shape {
		display: block;
		width: 32px;
		margin: 0 auto 8px;
		height: 30px;
		background: url(../img/shapes.png) no-repeat 0 0;
	}

	.shape_0 {
		background-position: 0 0;
	}
	.shape_1 {
		background-position: -32px 0;
	}
	.shape_2 {
		background-position: -64px 0;
	}
	.shape_3 {
		background-position: -96px 0;
	}
}

.searchResults {
	padding: 0 20px;
	margin-top: 35px;
}

html.darkLayout {
	$dark-bck: #070707;

	&, body {
		background: $dark-bck;
		color: #fff;
	}

	#header {
		background: $dark-bck;

		.logo {
			a {
				display: block;
				background: url(../img/logo_light.png) no-repeat 0 0;
			}

			img {
				opacity: 0;
			}
		}

		.mainMenu > ul > li > a {
			color: #fff;
		}

		&.overHeader {
			border-color: #3b3b3b;
		}
	}

	a {
		color: #fff;
	}

	.collectionName {
		color: #fff;
	}

	.productsList .wrapper {
		color: #fff;
	}

	#footer {
		background: $dark-bck;
		color: #fff;
		border-color: #3b3b3b;
	}

	.footerBlocks .catalogue .orderInfo {
		color: #fff;
	}

	.smallColors {
		.item {
			&:before {
				background: #3d3d3f;
			}
		}
	}

	.simpleTable {
		td, th {
			background: #3d3d3f;
			border-color: $dark-bck;
		}
	}

	.productDownloads {
		a {
			background-image: url(../img/files_light.png);
		}
	}

	.searchForm {
		color: #fff;

		.advanced {
			color: #fff;
		}

		.query {
			background: #fff;

			.clearForm {
				right: 5px;
			}
		}
	}

	.catProdList {
		.catName {
			color: #fff;
		}
	}

	.niceInputs .input label:after {
		background: #fff;
	}

	.inverseImg {
		img {
			margin: -40px 0 0;
		}

		&.footerSlogan img {
			margin: -37px 0 0;
		}

		&.footerOrder img {
			margin: -50px 0 0;
		}
	}
}

.searchPlace {
	overflow: hidden;
	position: relative;
	margin: 0 !important;
	padding: 0 !important;

	.searchIndex {
		overflow: hidden;
		position: relative;
	}

	.banners {
		display: none;
	}

	.searchForm {
		margin-top: 0;
	}
}

.inverseImg {
	display: inline-block;
	overflow: hidden;

	img {
		display: block;
		margin: 0 0 -40px;
	}

	&.footerSlogan img {
		margin: 0 0 -37px;
	}

	&.footerOrder img {
		margin: 0 0 -50px;
	}
}

.newsContent {
	overflow: hidden;

	.newsPageText {
		float: right;
		max-width: 300px;
		margin-left: 20px;
	}

	.newsList {
		overflow: hidden;
	}
}

.newsIndex {
	.newsList {
		margin-top: 10px;
	}
}

.newsList {
	.newsItem {
		margin: 0 0 20px;
		overflow: hidden;
	}

	.name {
		margin: 0 0 5px;
		font-size: 24px;
	}

	.when {
		display: block;
		font-size: 13px;
		margin-bottom: 10px;
	}

	.img {
		margin: 10px 0;

		img {
			max-width: 100%;
			height: auto;
		}
	}

	.readMore {
		position: relative;

		span {
			display: inline-block;
			background: #fff;
			padding-left: 15px;
			position: relative;
			z-index: 20;
		}

		&:before {
			content: ' ';
			position: absolute;
			display: block;
			z-index: 15;
			left: 0;
			right: 0;
			top: 50%;
			border-top: 2px solid #e8e8e8;
			margin-top: 1px;
		}
	}
}

.paginator {
	margin: 30px 0 0;
	line-height: 30px;

	a, span {
		display: inline-block;
		vertical-align: text-bottom;
		height: 30px;
	}

	.prev {
		margin-right: 10px;
	}

	.next {
		margin-left: 10px;
	}

	.number {
		width: 30px;
		text-align: center;
		margin: 0 2px;

		a {
			display: block;
			background: $color-base;
			color: #fff;
			@include transition();

			&:hover {
				background: $color-base-alt;
			}
		}
	}
}

.prevNext {
	overflow: hidden;

	.prev {
		float: left;
	}

	.next {
		float: right;
	}
}

body.inSearch {
	#header .nav  {
		.search {
			.button {
				opacity: .3;
			}
		}
	}
}
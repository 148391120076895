$color-base: #91ba18;
$color-base-alt: #567901;
$color-text: #646464;
$color-highlight: #91ba18;
$color-alt-highlight: #505052;
$color-dark: #3b3d3f;
$color-light: #ccc;

$width-axis: 960px;

$max-categories: 12;

$font-text: Bariol, Helvetica, Arial, sans-serif;

$font-size: 1.3rem;
$line-height: 1.5;

@mixin prefix($attribute, $value, $prefixes: webkit moz ms o) {
	@each $vendor in $prefixes {
		-#{$vendor}-#{$attribute}: $value;
	}
	#{$attribute}: $value;
}

@mixin opacity($opacity) {
	opacity: $opacity;
	filter: alpha(opacity=$opacity*100);
	*zoom: 1;
}

@mixin background-opacity($color, $opacity: 0.3) {
	$rgba: rgba($color, $opacity);
	$ie-hex-str: ie-hex-str($rgba);
	background-color: $color;
	background-color: $rgba;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#{$ie-hex-str}, endColorstr=#{$ie-hex-str});
	*zoom: 1;
}

@function parseInt($n) {
	@return $n / ($n * 0 + 1);
}

@mixin rem($property, $values) {
	$px: ();
	$rem: ();

	@each $value in $values {

		@if $value == 0 or $value == auto {
			$px: append($px, $value);
			$rem: append($rem, $value);
		} @else {
			$unit: unit($value);
			$val: parseInt($value);

			@if $unit == "px" {
				$px: append($px, $value);
				$rem: append($rem, ($val / 10 + rem));
			}

			@if $unit == "rem" {
				$px: append($px, ($val * 10 + px));
				$rem: append($rem, $value);
			}
		}
	}

	@if $px == $rem {
		#{$property}: $px;
	} @else {
		#{$property}: $px;
		#{$property}: $rem;
	}
}

@mixin list($item-width, $items-count, $class: '.items', $item-class: '.item', $base-width: $width-axis) {
	$margin-right: ($base-width - $item-width * $items-count) / ($items-count - 1);

	#{$class} {
		width: $base-width + $margin-right;

		#{$item-class} {
			width: $item-width;
			margin-right: $margin-right;
		}
	}
}

@mixin inRow($margin, $itemClass: '.item', $min: 1, $max: 12) {
	overflow: hidden;
	margin: 0 (-$margin);
	font-size: 1px;
	line-height: 0;

	#{$itemClass} {
		display: inline-block;
		vertical-align: middle;
		overflow: hidden;
		font-size: $font-size;
		line-height: $line-height;
		@include prefix(box-sizing, border-box);
	}

	@for $i from $min through $max {
		&.count_#{$i} {
			#{$itemClass} {
				width: (100% / $i);
				padding: 0 $margin;
			}
		}
	}
}

@mixin transition($element: all, $speed: 500ms, $easing: ease) {
	@include prefix(transition, $element $speed $easing);
}

@mixin font-face($name, $file, $weight: normal, $style: normal) {
	@font-face {
		font-family: $name;
		src: url('../fonts/#{$file}.eot');
		src: local('☺'),
		url('../fonts/#{$file}.eot?#iefix') format('embedded-opentype'),
		url('../fonts/#{$file}.woff2') format('woff2'),
		url('../fonts/#{$file}.woff') format('woff'),
		url('../fonts/#{$file}.ttf') format('truetype');
		font-weight: $weight;
		font-style: $style;
	}
}

@mixin grayScale($level) {
	@include prefix(filter, grayscale($level));
	filter: url('../js/grayscale.svg#grayscale');
	filter: gray;
}